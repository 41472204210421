import { Button, Center, Flex, Heading, Text } from "@chakra-ui/react"
import { CCVStack } from "@components/CCVStack"
import { colors } from "@styles/colors"
import { useRouter } from "next/router"
import { useEffect } from "react"

const AUTO_BROWSER_BACK_LIMIT_SEC = 5

const Custom404 = () => {
	const router = useRouter()

	useEffect(() => {
		let timerId = setTimeout(router.back, AUTO_BROWSER_BACK_LIMIT_SEC * 1000)
		return () => {
			clearTimeout(timerId)
		}
	}, [])

	return (
		<Center w="100vw" h="100vh">
			<CCVStack justify={"center"} align="center" spacing={4}>
				<Heading size="lg" color={colors.text.black[400]}>
					そのページは存在しないようです
				</Heading>
				<Center w="100%">
					<Button variant="link" onClick={router.back}>
						前の画面はこちらから
					</Button>
				</Center>
				<Flex w="100%" justify="center">
					<Text mt={20}>
						{AUTO_BROWSER_BACK_LIMIT_SEC}秒後に前の画面に戻ります
					</Text>
				</Flex>
			</CCVStack>
		</Center>
	)
}

export default Custom404
